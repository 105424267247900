import { FC } from 'react';
import { IAccountResourceData, IResource, IResourcePackage } from '../../../Services/SakuraApiClient';

export interface FormationViewProps {
    resource: IResource;
    resourcePackage: IResourcePackage;
    accountResourceData: IAccountResourceData;
}
export const FormationView: FC<FormationViewProps> = (props: FormationViewProps) => {
    const { resource } = props;
    return <span>FORMATION {resource.id}</span>;
};
