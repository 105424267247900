import { IQuestionnaryQuestion, IQuestionnarySubmissionItem } from '../../../Services/SakuraApiClient';
import { ValueTyping } from '../../../common/components/ExpressionField/Expression';

export function getDynVariableChange(question: IQuestionnaryQuestion, answer: Partial<IQuestionnarySubmissionItem>) {
    const changes: Record<string, ValueTyping & Record<string, ValueTyping | Record<string, ValueTyping>>> = {};

    if (question?.id) {
        changes[question?.id] = {};
        if (answer.freeText !== undefined) {
            changes[question?.id].reponse = answer.freeText;
        }
        if (answer.answerYes !== undefined) {
            changes[question?.id].reponse = answer.answerYes;
        }

        const choix = answer.answers ? answer.answers.join(',') : '';
        changes[question?.id].choix = choix;
        const choixTexte: string[] = [];
        question.answers?.forEach((a) => {
            const indexAnswer = answer.answers?.findIndex((item) => item === a.id);
            if (question?.id && a?.id) {
                if (indexAnswer !== -1) {
                    choixTexte.push(a.text ?? '');
                }
                changes[question?.id][a.id] = {
                    selectionner: indexAnswer !== -1,
                };
            }
        });
        if (question.answers && question.answers.length > 0) {
            changes[question?.id].choixTexte = choixTexte.join(',') ?? '';
        }
    }
    return changes;
}
