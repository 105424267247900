import React, { CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleDialog } from '../../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { IQuestionnary } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { QuestionnaryUI } from '../../../Common/Questionnary/QuestionnaryUI';

export interface QuestionnaryTesterProps {
    questionnaryResourceId: number;
    onClose: () => void;
}

export const QuestionnaryTester: FC<QuestionnaryTesterProps> = (props: QuestionnaryTesterProps) => {
    const { questionnaryResourceId, onClose } = props;

    const [questionnary, setQuestionnary] = useState<IQuestionnary | undefined>();
    const dispatch = useDispatch();
    useEffect(() => {
        performApiCall(async (apiCall) => {
            const result = await apiCall.getQuestionnary(questionnaryResourceId);
            setQuestionnary(result);
        }, dispatch);
    }, [questionnaryResourceId]);

    return (
        <>
            {questionnary ? (
                <SimpleDialog id={'previewQuestionnary'} title={'Prévisualisation'} show={true} icon={{ iconName: 'PreviewLink' }} onClose={onClose}>
                    <div className='questionnaryTester'>
                        <QuestionnaryUI questionnary={questionnary} resourceId={questionnaryResourceId} enablePersitance={false} onClosed={() => onClose()} />
                    </div>
                </SimpleDialog>
            ) : (
                <></>
            )}
        </>
    );
};
