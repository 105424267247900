import { Reducer } from 'react';
import { IPageElement } from '../../../../Services/SakuraApiClient';
import { IExecutionContextState, IResourceInfo } from './IExecutionContextState';

export type ExecutionDataContextAction =
    | { type: 'setResources'; payload: { resources: Record<number, IResourceInfo> } }
    | { type: 'addResource'; payload: { resourceId: number; data: IResourceInfo } };

export type ExecutionDataContextReducerType = Reducer<IExecutionContextState, ExecutionDataContextAction>;
export const ExecutionDataContextReducer = (state: IExecutionContextState, action: ExecutionDataContextAction): IExecutionContextState => {
    let newState = state;
    switch (action.type) {
        case 'setResources':
            newState = { ...state, resources: action.payload.resources };
            break;
        case 'addResource':
            const dic = { ...state.resources };
            dic[action.payload.resourceId] = action.payload.data;
            newState = { ...state, resources: dic };
            break;
    }
    if (state.serializationIdentifier) {
        localStorage.setItem(`ExecData_${state.serializationIdentifier}`, JSON.stringify(newState));
    }
    return newState;
};
