import { Dropdown, IDropdownOption, Label, Pivot, PivotItem, TextField } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { CheckFieldType, IPageElementCheckField } from '../../../../../Services/SakuraApiClient';
import { InputElementPanel } from './InputElementPanel';
import { ColorPalettePicker } from '../../../../../common/components/Color/ColorPalettePicker';
import { DimensionsInput } from '../../../../../common/components/Css/DimensionsInput';
import { CssCommonField } from '../Common/CssCommonField';
import { FontStyle } from '../Common/FontStyle';

export interface CheckFieldElementPanelProps {
    data: IPageElementCheckField;
    onChangeData: (data: DeepPartial<IPageElementCheckField>) => void;
}

const displayType: IDropdownOption[] = [
    {
        key: CheckFieldType.CheckBox,
        text: 'case à cocher',
    },
    {
        key: CheckFieldType.Toogle,
        text: 'Bascule',
    },
    {
        key: CheckFieldType.Art,
        text: 'Art Style',
    },
];

const illustration: IDropdownOption[] = [
    {
        key: 'check',
        text: 'case à cocher',
    },
    { key: 'checkbox', text: 'case à cocher au stylo' },
    { key: 'circle', text: 'rond' },
    { key: 'radio', text: 'rond au stylo' },
];

export const CheckFieldElementPanel: FC<CheckFieldElementPanelProps> = (props: CheckFieldElementPanelProps) => {
    const { onChangeData, data } = props;
    const [trueValue, setTrueValue] = useState<string | undefined>(data.trueValue);

    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <InputElementPanel data={data} onChangeData={onChangeData} />
                    <TextField
                        disabled={!data.assignValueTo}
                        label='Valeur si cochée'
                        title='défini la valeur que doit prendre la variable si la case est cochée.'
                        value={trueValue}
                        onBlur={() => onChangeData({ trueValue })}
                        onChange={(_, value) => setTrueValue(value)}
                    />
                    <Dropdown
                        label='Visuel'
                        options={displayType}
                        selectedKey={data.fieldType}
                        onChange={(_, option) => {
                            onChangeData({ fieldType: option?.key as CheckFieldType });
                        }}
                    ></Dropdown>
                    {data.fieldType === CheckFieldType.Art ? (
                        <>
                            <Dropdown
                                label='Illustration'
                                options={illustration}
                                selectedKey={data.artStyleName}
                                onChange={(_, option) => {
                                    onChangeData({ artStyleName: option?.key as string });
                                }}
                            ></Dropdown>
                            <Label>{'Couleur'}</Label>
                            <ColorPalettePicker
                                type='b'
                                idAsCssVariable
                                colorId={data.color}
                                onChange={(color) => {
                                    onChangeData({ color });
                                }}
                            />
                            <DimensionsInput
                                label='Dimensions'
                                fixed
                                dimension={data.dimensions}
                                onChange={(dimensions) =>
                                    onChangeData({
                                        dimensions,
                                    })
                                }
                            />
                        </>
                    ) : null}
                </PivotItem>
                <PivotItem headerText='Style'>
                    <FontStyle
                        font={data.style?.font}
                        onChangeFont={(font) => {
                            onChangeData({ style: { font } });
                        }}
                    />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
