import { FC, useMemo } from 'react';
import { mobileViewInContextSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IAccountResourceData, IPageElementContainer, IResourceData, IResourceDataPage } from '../../../Services/SakuraApiClient';
import { ExecutionDataContextProvider } from './ExecutionContext/ExecutionDataContextProvider';
import { PageElementView } from './PageElementView';

import { IResourceInfo } from './ExecutionContext/IExecutionContextState';
import './Page.scss';
import { useLoadScopeDynamicData } from './hook/useLoadScopeDynamicData';
export interface PageViewProps {
    pageResourceId: number | undefined;
    resourceData: IResourceData;
    pageData: IResourceDataPage;
    resources: Record<number, IResourceInfo>;
    resourceAccountData: IAccountResourceData | undefined;
}

export const PageView: FC<PageViewProps> = (props: PageViewProps) => {
    const { resourceData, pageData, resources, resourceAccountData, pageResourceId } = props;
    const isMobile = useAppSelector(mobileViewInContextSelector);
    useLoadScopeDynamicData('page', resourceData, resourceAccountData, false);

    const pageElement = useMemo(() => {
        if (isMobile && ((pageData.mobilePage as unknown as IPageElementContainer).items ?? []).length > 0) {
            return pageData.mobilePage;
        } else {
            return pageData.page;
        }
    }, [isMobile, pageData.mobilePage, pageData.page]);
    return (
        <ExecutionDataContextProvider pageResourceId={pageResourceId} resources={resources} serializationIdentifier={undefined}>
            <PageElementView data={pageElement} />
        </ExecutionDataContextProvider>
    );
};
