import { CancelToken } from 'axios';
import { SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { store } from '../../../store';
import { DataSetName } from '../DataSetName';
import { ClearData, SetData } from '../reducer';
import { DataSetInfo, DataSetInfoChange, FilterValue } from '../state';

export interface GenericQueryResult {
    totalRecords: number;
    totalPages: number;
    pageIndex: number;
    pageSize: number;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    result: any[] | undefined;
}
export interface FilterChange {
    mode: 'Partial' | 'Full';
    change: Record<string, FilterValue> | undefined;
}
function hasKeyDefinedWithValue(obj: Record<string, FilterValue>) {
    const keys = Object.keys(obj);
    if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
            const val = obj[keys[i]];
            if (val !== undefined && val !== '') {
                return true;
            }
        }
    }
    return false;
}
function checkSameFilter(filtersChange: Record<string, FilterValue> | undefined, filtersChange2: Record<string, FilterValue> | undefined) {
    if (filtersChange && filtersChange2) {
        return JSON.stringify(filtersChange) === JSON.stringify(filtersChange2);
    }
    if (filtersChange === undefined && filtersChange2 && !hasKeyDefinedWithValue(filtersChange2)) {
        return true;
    }
    if (filtersChange2 === undefined && filtersChange && !hasKeyDefinedWithValue(filtersChange)) {
        return true;
    }
    return false;
}
type ContextCall = 'init' | 'refresh' | 'update';

export class ApiCall {
    public datasetName: DataSetName;
    public lastResult: GenericQueryResult | undefined;
    public parameters: Record<string, FilterValue> | undefined;
    private initializing: boolean;
    private query: (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => Promise<GenericQueryResult>;

    public constructor(datasetName: DataSetName, query: (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => Promise<GenericQueryResult>) {
        this.datasetName = datasetName;
        this.query = query;
        this.initializing = false;
    }
    public canShowData(filter: Record<string, FilterValue>, filtersChange: FilterChange | undefined) {
        return checkSameFilter(filter, filtersChange?.change);
    }
    public async init(contextId = 'default', filtersChange: FilterChange | undefined = undefined, parameters?: Record<string, FilterValue>, cancelToken?: CancelToken) {
        if (this.initializing) {
            return;
        }
        const needRefresh = this.parameters && JSON.stringify(this.parameters) !== JSON.stringify(parameters);
        this.parameters = parameters;
        this.initializing = true;
        await this.call(needRefresh ? 'refresh' : 'init', undefined, filtersChange, contextId, cancelToken);
        this.initializing = false;
    }
    public async refresh(contextId = 'default', cancelToken?: CancelToken) {
        return await this.call('refresh', undefined, undefined, contextId, cancelToken);
    }
    public clear() {
        const dispatch = store.dispatch;
        dispatch(ClearData({ dataSetName: this.datasetName }));
        this.lastResult = undefined;
    }
    public async update(change: Partial<DataSetInfoChange> | undefined, filtersChange: FilterChange | undefined = undefined, contextId = 'default', cancelToken?: CancelToken) {
        return await this.call('update', change, filtersChange, contextId, cancelToken);
    }
    private async call(context: ContextCall, change: Partial<DataSetInfoChange> | undefined, filtersChange: FilterChange | undefined = undefined, contextId = 'default', cancelToken?: CancelToken) {
        return await this.callApi(context, this.datasetName, contextId, change, filtersChange, this.query, cancelToken);
    }
    public PatchData<T>(patchFunc: (item: T) => T | undefined) {
        if (this.lastResult) {
            const dispatch = store.dispatch;
            const newResult = this.lastResult.result?.map((r) => {
                const updatedR = patchFunc(r);
                debugger;
                return updatedR;
            });
            debugger;
            this.lastResult = { ...this.lastResult, result: newResult };
            const state = store.getState().dataset.data[this.datasetName];
            dispatch(
                SetData({
                    dataSetName: this.datasetName,
                    data: {
                        data: newResult,
                        pageIndex: this.lastResult.pageIndex,
                        pageSize: this.lastResult.pageSize,
                        filters: state.filters,
                        totalCount: this.lastResult.totalRecords,
                        totalPages: this.lastResult.totalPages,
                    },
                }),
            );
        }
    }
    private async callApi(
        context: ContextCall,
        dataSetName: DataSetName,
        contextId: string,
        change: Partial<DataSetInfoChange> | undefined,
        filters: FilterChange | undefined,
        query: (apiCall: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => Promise<GenericQueryResult>,
        cancelToken?: CancelToken,
    ) {
        const dispatch = store.dispatch;
        const state = store.getState().dataset.data[dataSetName];
        return await performApiCall(
            async (client, cancelationToken) => {
                const hasChangeFilter = filters ? !checkSameFilter(filters?.change, state.filters) : false;
                if (context === 'init' && state.data.length !== 0 && (filters === undefined || hasChangeFilter == false)) {
                    return; // do nothing
                }

                const newState: DataSetInfo = {
                    ...state,
                    ...change,
                    filters: hasChangeFilter ? (filters?.mode === 'Full' ? filters.change ?? {} : { ...state.filters, ...filters?.change }) : state.filters,
                };
                if (hasChangeFilter) {
                    newState.pageIndex = 0; //if filter change back to page zero automaticaly
                }
                const hasChange = newState.pageIndex !== state.pageIndex || newState.pageSize !== state.pageSize || hasChangeFilter;
                if (context !== 'update' || hasChange) {
                    const result = await query(this, client, newState, cancelationToken);
                    this.lastResult = result;
                    dispatch(
                        SetData({
                            dataSetName,
                            data: {
                                data: result.result,
                                pageIndex: result.pageIndex,
                                pageSize: result.pageSize,
                                filters: newState.filters,
                                totalCount: result.totalRecords,
                                totalPages: result.totalPages,
                            },
                        }),
                    );
                }
            },
            dispatch,
            { contextId, cancelToken },
        );
    }
}
