import React, { CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { performApiCall } from '../../../../../common/Hooks/useApiCall';
import { IPageElementQuestionnary, Questionnary } from '../../../../../Services/SakuraApiClient';
import { QuestionnaryUI } from '../../../Questionnary/QuestionnaryUI';
import { Icon } from '@fluentui/react';

export interface ElementQuestionnaryViewProps {
    data: IPageElementQuestionnary;
}

export const ElementQuestionnaryView: FC<ElementQuestionnaryViewProps> = (props: ElementQuestionnaryViewProps) => {
    const { data } = props;

    const [questionnary, setQuestionnary] = useState<Questionnary | undefined>();
    const dispatch = useDispatch();
    useEffect(() => {
        performApiCall(async (apiCall) => {
            if (data.resource?.id && (data.resource?.expiredOn === undefined || data.resource?.expiredOn > new Date(Date.now()))) {
                const result = await apiCall.getQuestionnary(data.resource.id);
                setQuestionnary(result);
            } else {
                setQuestionnary(undefined);
            }
        }, dispatch);
    }, [data.resource?.id, data.resource?.expiredOn]);

    return (
        <>
            {questionnary && data.resource?.id ? (
                <QuestionnaryUI resourceId={data.resource.id} questionnary={questionnary} enablePersitance />
            ) : (
                <div className='DivFlexHorizontalCenter' style={{ justifyContent: 'center' }}>
                    <Icon iconName='Warning' />
                    <span>Choisi un questionnaire</span>
                </div>
            )}
        </>
    );
};
