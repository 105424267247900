import { ActionButton, Callout, DirectionalHint, Icon, mergeStyleSets } from '@fluentui/react';
import { FC, useMemo, useRef, useState } from 'react';
import { colorsPaletteSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { generateUniqueId } from '../../helpers/uniqueId';
import { getIdFromCssVar, idToCssVar } from '../../styles/common';
import { ColorPaletteEditorDialog } from './ColorPaletteEditorDialog';
import './ColorPalettePickerButton.scss';
import { ColorPaletteView } from './ColorPaletteView';

export interface ColorPalettePickerProps {
    type: 'f' | 'b';
    colorId: string | undefined;
    idAsCssVariable?: boolean;
    onChange: (colorId: string | undefined) => void;
}
const styles = mergeStyleSets({
    callout: {
        width: 320,
        padding: '20px 24px',
    },
});

export const ColorPalettePicker: FC<ColorPalettePickerProps> = (props: ColorPalettePickerProps) => {
    const { colorId, onChange, type, idAsCssVariable } = props;
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>();
    const buttonId = useRef(generateUniqueId('targetButton'));
    const colorsPalette = useAppSelector(colorsPaletteSelector);

    const [editPalette, setEditPalette] = useState<boolean>(false);
    const colorIdentifier = useMemo(() => {
        return getIdFromCssVar(colorId);
    }, [colorId]);
    return (
        <>
            <div className='colorPalettePickerButton' title={type === 'f' ? 'Couleur du texte' : 'Couleur de fond'} id={buttonId.current} onClick={() => setIsCalloutVisible(true)}>
                <Icon iconName={type === 'f' ? 'FontColor' : 'BucketColor'} />
                <div style={{ height: 4, background: colorIdentifier ? `var(${colorIdentifier})` : type === 'f' ? 'black' : 'transparent', marginTop: -5 }}></div>
                {isCalloutVisible ? (
                    <Callout
                        ariaLabelledBy={'color picker'}
                        ariaDescribedBy={'color picker'}
                        role='dialog'
                        className={styles.callout}
                        target={`#${buttonId.current}`}
                        isBeakVisible
                        beakWidth={10}
                        onDismiss={() => {
                            setIsCalloutVisible(false);
                        }}
                        directionalHint={DirectionalHint.bottomCenter}
                        setInitialFocus
                    >
                        <div>
                            <ActionButton
                                text='Modifier la palette de couleur'
                                iconProps={{ iconName: 'Edit' }}
                                onClick={() => {
                                    setEditPalette(true);
                                }}
                            />
                            <ColorPaletteView
                                selectedId={colorIdentifier}
                                palette={colorsPalette}
                                disableGradiantColorSelection={type === 'f'}
                                onSelected={(id) => {
                                    onChange(idAsCssVariable ? idToCssVar(id) : id);
                                    setIsCalloutVisible(false);
                                }}
                            />
                        </div>
                    </Callout>
                ) : null}
            </div>

            {editPalette ? <ColorPaletteEditorDialog show={editPalette} onClose={() => setEditPalette(false)} /> : undefined}
        </>
    );
};
