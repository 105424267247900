import { FC, useState } from 'react';
import { QuestionnaryResult } from '../Pages/Common/Questionnary/SubmitQuestionnary/QuestionnaryResult';
import { setStorageEndpoints, useMessageSelector } from '../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../Redux/hook';
import { IQuestionnary, IQuestionnaryResult } from '../Services/SakuraApiClient';
import { ErrorPage } from '../common/ErrorPage';
import { useApiCall } from '../common/Hooks/useApiCall';
import { InvalidLinkPage } from '../common/InvalidLinkPage';
import { LayoutPublic } from '../common/LayoutPublic/LayoutPublic';
import { LoadingScreen } from '../common/LoadingScreen';
import { MarketingOffer, ViewMarketingOffer } from './ViewMarketingOffer';

interface ViewQuestionnaryResultState {
    result: IQuestionnaryResult;
    questionnary: IQuestionnary;
    offer: MarketingOffer;
}
export const ViewQuestionnaryResult: FC = () => {
    const [state, setState] = useState<ViewQuestionnaryResultState | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const error = useMessageSelector();
    const dispatch = useAppDispatch();
    const [closed, setClosed] = useState<boolean>(false);

    useApiCall(
        async (client, cancelToken) => {
            const values = await client.getProtectedValueOfQueryString(window.location.href, cancelToken);
            const questionnaryResultId = values['id'];
            const result = await client.getQuestionnaryResultForAnonymouseUser(questionnaryResultId.protected, cancelToken);

            dispatch(setStorageEndpoints({ storage: result.storageInformation }));
            setState({
                result: result.result,
                questionnary: result.questionnary,
                offer: {
                    showCurrentOffers: values['o'],
                    offerId1: values['o1'],
                    offerId2: values['o2'],
                },
            });
            setIsLoading(false);
        },
        [],
        { anonymous: true },
    );
    return (
        <>
            {error && <ErrorPage />}
            {!error && (
                <>
                    {isLoading ? (
                        <LoadingScreen text='Bienvenue &#x1F603;' />
                    ) : (
                        <LayoutPublic>
                            {state?.result ? (
                                <>
                                    {closed ? (
                                        <ViewMarketingOffer enabled={true} offer={state.offer} />
                                    ) : (
                                        <div className='questionnaryRoot'>
                                            <div className='questionnaryUI'>
                                                <QuestionnaryResult questionnary={state.questionnary} result={state.result} />
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <InvalidLinkPage />
                            )}
                        </LayoutPublic>
                    )}
                </>
            )}
        </>
    );
};
