import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadPalette } from '../../../common/styles/ColorPalette';
import { loadFontStyles } from '../../../common/styles/FontStyles';
import { useAppSelector } from '../../hook';
import type { RootState } from '../../store';
import {
    ClearContextAction,
    NotificationSubscribed,
    ServiceWorkerRegistered,
    SetColorPaletteAction,
    SetConfiguredLinks,
    SetContextualMobileDisplay,
    SetDirtyFormAction,
    SetFontStylesAction,
    SetLoadingAction,
    SetMessageAction,
    SetPopupMessageAction,
    SetPreventLostDataAction,
    SetPushNotificationPublicKey,
    SetSelectedThumbnail,
    SetStorageEndpointsAction,
    SetThumbnailSectionsAction,
    SetWindowWidth,
} from './action';
import { initialState } from './state';

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        showMobile: (state) => {
            if (!state.mobileDisplay) {
                state.mobileDisplay = true;
            }
        },
        showDesktop: (state) => {
            if (state.mobileDisplay) {
                state.mobileDisplay = false;
            }
        },
        setContextualMobileDisplay: (state, action: PayloadAction<SetContextualMobileDisplay>) => {
            state.contextualMobileDisplay = action.payload.mobile;
        },
        setWindowWidth: (state, action: PayloadAction<SetWindowWidth>) => {
            state.windowWidth = action.payload.width;
        },
        setLoading: (state, action: PayloadAction<SetLoadingAction>) => {
            state.loading[action.payload.contextId] = action.payload.loading;
        },
        setMessage: (state, action: PayloadAction<SetMessageAction>) => {
            state.message[action.payload.contextId] = action.payload.message;
        },
        setPopupMessage: (state, action: PayloadAction<SetPopupMessageAction>) => {
            state.popupMessage = action.payload.message;
        },
        clearContext: (state, action: PayloadAction<ClearContextAction>) => {
            state.message[action.payload.contextId] = undefined;
            state.loading[action.payload.contextId] = undefined;
        },
        setPreventLostData: (state, action: PayloadAction<SetPreventLostDataAction>) => {
            state.preventLostData = action.payload.callback;
            if (action.payload.resetDirtyForm) {
                state.dirtyForm[action.payload.contextId] = false;
            }
        },
        setDirtyForm: (state, action: PayloadAction<SetDirtyFormAction>) => {
            state.dirtyForm[action.payload.contextId] = action.payload.dirtyForm;
        },
        notifyTokenExpired: (state) => {
            console.log('state.hasTokenExpired = true');
            state.hasTokenExpired = true;
        },
        serviceWorkerRegistered: (state, action: PayloadAction<ServiceWorkerRegistered>) => {
            state.serviceWorkerRegistration = action.payload.serviceWorkerRegistration;
            state.notification.permission = action.payload.notificationPermission;
        },
        notificationSubscribed: (state, action: PayloadAction<NotificationSubscribed>) => {
            state.notification.permission = action.payload.notificationPermission;
            state.notification.endpoint = action.payload.endpoint;
            state.notification.auth = action.payload.auth;
            state.notification.p256dh = action.payload.p256dh;
            state.notification.id = action.payload.id;
        },
        notificationUnsubscribed: (state) => {
            state.notification.permission = 'default';
            state.notification.endpoint = undefined;
            state.notification.auth = undefined;
            state.notification.p256dh = undefined;
            state.notification.id = undefined;
        },
        setPushNotificationPublicKey: (state, action: PayloadAction<SetPushNotificationPublicKey>) => {
            state.notificationPublicKey = action.payload.publicKey;
        },
        setConfiguredLinks: (state, action: PayloadAction<SetConfiguredLinks>) => {
            state.links = action.payload.links;
        },
        setSelectedThumbnail: (state, action: PayloadAction<SetSelectedThumbnail>) => {
            state.thumbnail.selected = action.payload;
        },
        clearSelectedThumbnail: (state) => {
            state.thumbnail.selected = undefined;
        },
        setColorPalette: (state, action: PayloadAction<SetColorPaletteAction>) => {
            if (action.payload.colors) {
                state.colors = action.payload.colors;
                loadPalette(state.colors);
            }
        },
        setFontStyles: (state, action: PayloadAction<SetFontStylesAction>) => {
            if (action.payload.fontStyles) {
                state.fontStyles = action.payload.fontStyles;
                loadFontStyles(state.fontStyles);
            }
        },
        setStorageEndpoints: (state, action: PayloadAction<SetStorageEndpointsAction>) => {
            state.storage = action.payload.storage;
        },
        setThumbnailSections: (state, action: PayloadAction<SetThumbnailSectionsAction>) => {
            state.thumbnailSections = action.payload.sections;
        },
    },
});

//Export Actions
export const {
    showMobile,
    showDesktop,
    setLoading,
    setMessage,
    clearContext,
    setPreventLostData,
    setDirtyForm,
    notifyTokenExpired,
    setPopupMessage,
    notificationSubscribed,
    notificationUnsubscribed,
    serviceWorkerRegistered,
    setPushNotificationPublicKey,
    setConfiguredLinks,
    setContextualMobileDisplay,
    setSelectedThumbnail,
    clearSelectedThumbnail,
    setColorPalette,
    setFontStyles,
    setThumbnailSections,
    setStorageEndpoints,
    setWindowWidth,
} = systemSlice.actions;
//Export Selector
export const mobileViewInContextSelector = (state: RootState): boolean => state.system.mobileDisplay || state.system.contextualMobileDisplay;
export const mobileViewSelector = (state: RootState): boolean => state.system.mobileDisplay;
export const contextualMobileDisplaySelector = (state: RootState): boolean => state.system.contextualMobileDisplay;

export const useLoadingSelector = (contextId = 'default') => {
    return useAppSelector((state: RootState) => state.system.loading[contextId]);
};
export const useMessageSelector = (contextId = 'default') => {
    return useAppSelector((state: RootState) => state.system.message[contextId]);
};
export const useDirtyFormSelector = () => {
    return useAppSelector((state: RootState) => state.system.dirtyForm);
};
export const useHasTokenExpired = () => {
    return useAppSelector((state: RootState) => state.system.hasTokenExpired);
};
export const usePopupMessage = () => {
    return useAppSelector((state: RootState) => state.system.popupMessage);
};
export const useNotificationSubscribed = () => {
    return useAppSelector((state: RootState) => state.system.notification);
};
export const useLinks = () => {
    return useAppSelector((state: RootState) => state.system.links);
};
export const preventLostDataSelectorViewSelector = (state: RootState): (() => void) | undefined => state.system.preventLostData;

export const useThumbnailInfo = () => {
    return useAppSelector((state: RootState) => state.system.thumbnail);
};
export const useWindowWidth = () => {
    return useAppSelector((state: RootState) => state.system.windowWidth);
};

export const colorsPaletteSelector = (state: RootState) => state.system.colors;
export const fontStylesSelector = (state: RootState) => state.system.fontStyles;
export const storageEndpointsSelector = (state: RootState) => state.system.storage;
export const thumbnailSectionsSelector = (state: RootState) => state.system.thumbnailSections;
//export Reducer
export default systemSlice.reducer;
