import { TextField, Toggle } from '@fluentui/react';
import React, { FC } from 'react';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeIcon, GetResourceTypeTextOne } from '../../../../common/components/Icons/ResourceTypeIcon';
import { useForm } from '../../../../common/Hooks/Form';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import * as validators from '../../../../common/Hooks/Form/validators';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';

export interface DialogResourcePropertyData {
    name?: string;
    title?: string | undefined;
    reusable?: boolean | undefined;
}
export interface DialogResourcePropertyProps {
    data: DialogResourcePropertyData;
    resourceType: ResourceTypeEnum;
    onChange: (data: DialogResourcePropertyData) => void;
    onClose: () => void;
}
export const DialogResourceRename: FC<DialogResourcePropertyProps> = (props: DialogResourcePropertyProps) => {
    const { data, onChange, onClose, resourceType } = props;

    const form = useForm<DialogResourcePropertyData>(
        {
            initialState: data,
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        false,
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose();
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            form.commit();
            onChange({
                name: form.state.name,
                reusable: form.state.reusable,
                title: form.state.title,
            });
        }
    };
    return (
        <EditDialog
            id={'updateProperties'}
            icon={{ iconName: GetResourceTypeIcon(resourceType) }}
            mode={'Edit'}
            title={'Modification des propriétés'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Titre' errorMessage={form.fieldErrors.title} value={form.state.title ?? ''} onChange={(_, v) => form.update({ title: v })} />
                <Toggle
                    label='Partageable'
                    title={`${GetResourceTypeTextOne(resourceType)} partageable peut étre référencé dans different module de formation.`}
                    offText='Non'
                    onText='Oui'
                    checked={form.state.reusable}
                    onChange={(_, val) => form.update({ reusable: val })}
                />
            </div>
        </EditDialog>
    );
};
