import { FC, useState } from 'react';
import { QuestionnaryUI } from '../../Pages/Common/Questionnary/QuestionnaryUI';
import { IProtectedValueInfo, IPublicResource } from '../../Services/SakuraApiClient';
import { MarketingOffer } from '../ViewMarketingOffer';
import { ThanksPage } from './ThanksPage';
export interface ResourceQuestionnaryPageProps {
    resource: IPublicResource;
    queryString: Record<string, IProtectedValueInfo>;
    offer: MarketingOffer;
}

export const ResourceQuestionnaryPage: FC<ResourceQuestionnaryPageProps> = (props: ResourceQuestionnaryPageProps) => {
    const { resource, queryString, offer } = props;
    const [closed, setClosed] = useState<boolean>(false);
    return (
        <>
            {closed ? (
                <>
                    <ThanksPage offer={offer} resource={resource} />
                </>
            ) : (
                <QuestionnaryUI
                    questionnary={resource.questionnary}
                    resourceId={resource.id}
                    enablePersitance
                    showResult={queryString['sr']}
                    onClosed={() => {
                        setClosed(true);
                    }}
                />
            )}
        </>
    );
};
