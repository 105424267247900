import { IconButton, Label } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useRef } from 'react';

import './filePicker.scss';

export interface FilePickerProps {
    label?: string;
    required?: boolean;
    extension: string;
    errorMessage?: string;
    onChange: (file: File | null | undefined) => void;
}

export const FilePicker: FC<FilePickerProps> = (props: FilePickerProps) => {
    const { label, onChange, extension, required, errorMessage } = props;
    const fileInput = useRef<HTMLInputElement>(null);
    const onSelectFile = () => {
        if (fileInput.current) {
            fileInput.current.value = '';
            fileInput.current.click();
        }
    };
    const onChangeFile = () => {
        if (fileInput?.current?.files) {
            onChange(fileInput?.current?.files[0]);
        }
    };
    const currentFile = fileInput?.current?.files && fileInput?.current?.files.length > 0 ? fileInput?.current?.files[0] : undefined;
    return (
        <div className='filePicker'>
            {label && <Label required={required}>{label}</Label>}
            <div className={clsx('filePickerBox', errorMessage ? 'error' : '')}>
                <span>{currentFile?.name ?? ''}</span>
                <IconButton iconProps={{ iconName: 'OpenFolderHorizontal' }} onClick={onSelectFile} />
            </div>
            {errorMessage && <span className='spanError'>{errorMessage}</span>}
            <input hidden ref={fileInput} onChange={onChangeFile} accept={extension} type='file' />
        </div>
    );
};
