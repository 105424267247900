import { FC } from 'react';
import { IResourceConsumerPackage, IResourceData, IResourceDataPage } from '../../../Services/SakuraApiClient';
import { ResourcePageContent, getResourcesInfo } from './ResourcePageContent';

export interface ResourcePageViewProps {
    resourceConsumerPackage: IResourceConsumerPackage | undefined;
}

export const ResourcePageView: FC<ResourcePageViewProps> = (props: ResourcePageViewProps) => {
    const { resourceConsumerPackage } = props;
    return !resourceConsumerPackage ? (
        <></>
    ) : (
        <ResourcePageContent
            pageResourceId={resourceConsumerPackage?.resource?.id}
            pageData={resourceConsumerPackage?.resource?.data.content.page as IResourceDataPage}
            resourceData={resourceConsumerPackage?.resource?.data as IResourceData}
            innerResources={getResourcesInfo(resourceConsumerPackage?.resourcePackage?.innerResourcePackages ?? {})}
            resourceAccountData={resourceConsumerPackage?.accountResourceData}
        ></ResourcePageContent>
    );
};
