import { FC } from 'react';
import { IAccountResourceData, IResourceData, IResourceDataPage, IResourcePackage } from '../../../Services/SakuraApiClient';
import { IResourceInfo } from './ExecutionContext/IExecutionContextState';
import { PageView } from './PageView';

export interface ResourcePageProps {
    pageResourceId: number | undefined;
    resourceData: IResourceData;
    pageData: IResourceDataPage;
    innerResources: Record<number, IResourceInfo>;
    resourceAccountData: IAccountResourceData | undefined;
}
export function getResourcesInfo(resPackage: Record<string, IResourcePackage>): Record<number, IResourceInfo> {
    const resourcesInfo: Record<number, IResourceInfo> = {};
    const entries = Object.values(resPackage);
    entries.forEach((val) => {
        resourcesInfo[val.id] = { id: val.id, blobPath: val.blobPath, data: val.data, type: val.type };
    });
    return resourcesInfo;
}

export const ResourcePageContent: FC<ResourcePageProps> = (props: ResourcePageProps) => {
    const { pageData, pageResourceId, resourceData, innerResources, resourceAccountData } = props;

    return <PageView resources={innerResources} pageResourceId={pageResourceId} pageData={pageData} resourceData={resourceData} resourceAccountData={resourceAccountData} />;
};
