import { FC } from 'react';
import { IAccountResourceData, IResource } from '../../../Services/SakuraApiClient';

export interface AudioViewProps {
    resource: IResource;
    accountResourceData: IAccountResourceData;
}
export const AudioView: FC<AudioViewProps> = (props: AudioViewProps) => {
    const { resource } = props;

    return <span>AUDIO {resource.id}</span>;
};
