import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticationService } from './AuthService';

export interface ILoginCallbackProps {
    authService: AuthenticationService;
}
export const LoginCallback: FC<PropsWithChildren<ILoginCallbackProps>> = (props: PropsWithChildren<ILoginCallbackProps>) => {
    const { children, authService } = props;
    const location = useLocation();
    const history = useNavigate();
    const locationHash = location.hash;
    useEffect(() => {
        const asyncCall = async () => {
            const user = await authService.loginCallback(locationHash, history);
            if (user) {
                console.table(user);
            }
        };
        asyncCall();
    }, [locationHash, history]);
    return children ? <>{children}</> : <></>;
};
