import { Dropdown, IDropdownOption, Label, Pivot, PivotItem } from '@fluentui/react';
import { FC, useContext, useState } from 'react';
import { IResourceDataPage, PageElement } from '../../../Services/SakuraApiClient';

import { PageElementEdition } from './PageElementEdition';

import { setContextualMobileDisplay } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import '../Page/Page.scss';
import { EditorDataContext } from './EditorContext/EditorDataContextProvider';
import { ViewType } from './EditorContext/IEditorDataContextState';
import { deviceResolutions } from './mobileResolution';

export interface PageViewProps {
    pageData: IResourceDataPage | undefined;
    onChangePageData?: (data: IResourceDataPage) => void;
}

export const PageEdition: FC<PageViewProps> = (props: PageViewProps) => {
    const { pageData, onChangePageData } = props;
    const [mobileResolution, setMobileResolution] = useState<IDropdownOption | undefined>(deviceResolutions[0]);
    const editorContext = useContext(EditorDataContext);
    const dispatch = useAppDispatch();
    return (
        <Pivot
            linkSize='large'
            className='content'
            selectedKey={editorContext.viewType}
            onLinkClick={(item) => {
                if (item?.props) {
                    const viewType = item.props.itemKey as ViewType;
                    editorContext.changeViewType(viewType);
                    dispatch(setContextualMobileDisplay({ mobile: viewType === 'Mobile' }));
                }
            }}
        >
            <PivotItem headerText='Navigateur' itemKey='Desktop' itemIcon='ThisPC' className='contentView'>
                <div className='viewport'>
                    {pageData?.page ? (
                        <>
                            <PageElementEdition
                                data={pageData.page}
                                noDrag
                                onChangeData={(page) => {
                                    if (onChangePageData) {
                                        onChangePageData({ ...pageData, page });
                                    }
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </PivotItem>
            <PivotItem headerText='Mobile' itemIcon='CellPhone' itemKey='Mobile' className='contentViewMobile'>
                <div className='contentViewMobileHeader'>
                    <Dropdown style={{ width: 250 }} options={deviceResolutions} selectedKey={mobileResolution?.key} onChange={(_, opt) => setMobileResolution(opt)}></Dropdown>
                    <NumberField value={mobileResolution?.data.width} readOnly></NumberField>
                    <Label>X</Label>
                    <NumberField value={mobileResolution?.data.height} readOnly></NumberField>
                </div>
                <div className='contentViewMobileDevice'>
                    <div style={{ width: mobileResolution?.data.width, height: mobileResolution?.data.height }}>
                        {pageData?.mobilePage ? (
                            <>
                                <PageElementEdition
                                    noDrag
                                    data={pageData.mobilePage as PageElement}
                                    onChangeData={(page) => {
                                        if (onChangePageData) {
                                            onChangePageData({ ...pageData, mobilePage: page });
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </PivotItem>
        </Pivot>
    );
};
