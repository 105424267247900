import React, { FC } from 'react';
import { IAccountResourceData, IResource, IResourceDataPage } from '../../../../Services/SakuraApiClient';
import { IResourceInfo } from '../../Page/ExecutionContext/IExecutionContextState';
import { ExecutionDataContextProvider } from '../../Page/ExecutionContext/ExecutionDataContextProvider';
import { PageElementEdition } from '../PageElementEdition';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EditorDataContextProvider } from '../EditorContext/EditorDataContextProvider';

export interface PreviewTemplateProps {
    resource: IResource | undefined;
    innerResources: Record<number, IResourceInfo>;
    resourceAccountData: IAccountResourceData | undefined;
}

export const PreviewTemplate: FC<PreviewTemplateProps> = (props: PreviewTemplateProps) => {
    const { resource, innerResources } = props;

    const pageData = resource?.data.content.page as IResourceDataPage;
    return (
        <div style={{ pointerEvents: 'none' }} className='pageEditorStudio'>
            <DndProvider backend={HTML5Backend}>
                <ExecutionDataContextProvider pageResourceId={resource?.id} resources={innerResources} serializationIdentifier={undefined}>
                    <EditorDataContextProvider>
                        <PageElementEdition
                            data={pageData.page}
                            onChangeData={() => {
                                // no edit in preview
                            }}
                        />
                    </EditorDataContextProvider>
                </ExecutionDataContextProvider>
            </DndProvider>
        </div>
    );
};
