import { useEffect } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { IAccountResourceData, IResourceData, IResourceDataPage } from '../../../../Services/SakuraApiClient';
import { clearScope, loadScope } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { DataScope } from '../../../../Redux/Reducers/DynamiqueData/state';
import { scopeBuilder } from './ScopeBuilder/scopeBuilder';

export function useLoadScopeDynamicData(scope: DataScope, resourceData: IResourceData | undefined, resourceAccountData: IAccountResourceData | undefined, loadDefinition: boolean) {
    const appDispatch = useAppDispatch();

    useEffect(() => {
        if (resourceData) {
            const content = scopeBuilder[scope](resourceData, resourceAccountData, loadDefinition);
            appDispatch(
                loadScope({
                    scope,
                    content,
                }),
            );
            return () => {
                appDispatch(
                    clearScope({
                        scope,
                    }),
                );
            };
        }
        return undefined;
    }, []);
}
