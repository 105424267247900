import React, { FC, useMemo } from 'react';

import { IResultDescriptionBase } from '../../../../../Services/SakuraApiClient';
import { ResourceViewer } from '../../../../../common/components/ResourcePackage/ResourceViewer';
import { ParagraphAnimated } from '../../../../../common/components/ParagraphAnimated.tsx';
import { useDynamicData } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';

export interface ShowResultDescriptionProps {
    description: IResultDescriptionBase | undefined;
    immediate?: boolean;
}

export const ShowResultDescription: FC<ShowResultDescriptionProps> = (props: ShowResultDescriptionProps) => {
    const { description, immediate } = props;
    const dynData = useDynamicData();
    const resolvedTitle = useBracketResolver(description?.title, dynData);
    const resolvedDescription = useBracketResolver(description?.description, dynData);
    return description ? (
        <>
            <h2>{resolvedTitle}</h2>
            <div className='description'>
                {description.imageResourceId ? (
                    <div className='pic'>
                        <ResourceViewer resourceId={description.imageResourceId} />
                    </div>
                ) : (
                    <></>
                )}
                {resolvedDescription ? <div>{immediate ? <p>{resolvedDescription}</p> : <ParagraphAnimated speed={25}>{resolvedDescription}</ParagraphAnimated>}</div> : <></>}
            </div>
        </>
    ) : (
        <></>
    );
};
