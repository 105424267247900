import { Label, ProgressIndicator } from '@fluentui/react';
import { FC } from 'react';
import { xpRequiredSelector } from '../../../Redux/Reducers/User/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IAccount } from '../../../Services/SakuraApiClient';

interface SakuraPersonaStatusProps {
    userProfile: IAccount | undefined;
}
export const SakuraPersonaStatus: FC<SakuraPersonaStatusProps> = (props: SakuraPersonaStatusProps) => {
    const { userProfile } = props;

    const xpRequired = useAppSelector(xpRequiredSelector);
    return (
        <div className='SakuraPersonaStatus'>
            <div className='block'>
                <div className='subBlock'>
                    <Label>Lv</Label>
                    <span>{userProfile?.level ?? 0}</span>
                </div>
                <div className='subBlock'>
                    <img src='/images/sakura-flower.svg' title='Pétale de Sakura' alt='Sakura Pétale' tabIndex={-1} height={25} />
                    <span>{userProfile?.sakuraCoin ?? 0}</span>
                </div>
                <div className='subBlock'>
                    <img src='/images/blue-diamond.svg' title='Diamant' alt='Sakura Diamond' tabIndex={-1} height={22} />
                    <span>{userProfile?.sakuraDiamond ?? 0}</span>
                </div>
            </div>
            <ProgressIndicator barHeight={5} ariaValueText={`Point d'experience acquis ${userProfile?.experience ?? 0}`} percentComplete={(userProfile?.experience ?? 0) / xpRequired} />
        </div>
    );
};
