import { Dropdown, IDropdownOption, Label, TextField, Toggle } from '@fluentui/react';
import { FC, useContext } from 'react';
import { EnumBlockDirection, EnumDisplayMode, EnumItemsAlignement, EnumJustifyContent, IPageElementContainer } from '../../../../../Services/SakuraApiClient';
import { ResourceLookupField } from '../../../../../common/LookupFields/ResourceLookupField';
import { Accordion } from '../../../../../common/components/Accordion';
import { ColorPalettePicker } from '../../../../../common/components/Color/ColorPalettePicker';
import { CssUnitBox } from '../../../../../common/components/Css/CssUnitBox';
import { NumberField } from '../../../../../common/components/NumberField/NumberField';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ExecutionDataContext } from '../../../Page/ExecutionContext/ExecutionDataContextProvider';
import { Alignement } from '../Common/Alignment';
import { CssCommonField } from '../Common/CssCommonField';

export interface ContainerElementPanelProps {
    data: IPageElementContainer;
    onChangeData: (data: DeepPartial<IPageElementContainer>) => void;
}
function toVal(val: string | number | undefined) {
    return val == -1 ? undefined : val;
}
const orientations: IDropdownOption[] = [
    {
        key: EnumBlockDirection.Column,
        text: 'Verticale',
    },
    {
        key: EnumBlockDirection.Row,
        text: 'Horizontal',
    },
];

const enumItemsAlignementHorizontal: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: EnumItemsAlignement.Start,
        text: 'Gauche',
    },
    {
        key: EnumItemsAlignement.End,
        text: 'Droite',
    },
    {
        key: EnumItemsAlignement.Center,
        text: 'Centré',
    },
    {
        key: EnumItemsAlignement.Stretch,
        text: 'Etiré',
    },
    // {
    //     key: EnumItemsAlignement.BaseLine,
    //     text: 'Ligne de base',
    // },
];
const enumItemsAlignementVerticale: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: EnumItemsAlignement.Start,
        text: 'Haut',
    },
    {
        key: EnumItemsAlignement.End,
        text: 'Bas',
    },
    {
        key: EnumItemsAlignement.Center,
        text: 'Centré',
    },
    {
        key: EnumItemsAlignement.Stretch,
        text: 'Etiré',
    },
    // {
    //     key: EnumItemsAlignement.BaseLine,
    //     text: 'Ligne de base',
    // },
];
const enumJustifyContentHorizontal: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: EnumJustifyContent.Start,
        text: 'Gauche',
    },
    {
        key: EnumJustifyContent.End,
        text: 'Droite',
    },
    {
        key: EnumJustifyContent.Center,
        text: 'Centré',
    },
    {
        key: EnumJustifyContent.SpaceAround,
        text: 'Espace autour',
    },
    {
        key: EnumJustifyContent.SpaceBetween,
        text: 'Espace entre',
    },
];
const enumJustifyContentVertical: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: EnumJustifyContent.Start,
        text: 'Haut',
    },
    {
        key: EnumJustifyContent.End,
        text: 'Bas',
    },
    {
        key: EnumJustifyContent.Center,
        text: 'Centré',
    },
    {
        key: EnumJustifyContent.SpaceAround,
        text: 'Espace autour',
    },
    {
        key: EnumJustifyContent.SpaceBetween,
        text: 'Espace entre',
    },
    {
        key: EnumJustifyContent.SpaceEvenly,
        text: 'Espace reparti',
    },
];

const display: IDropdownOption[] = [
    {
        key: EnumDisplayMode.Block,
        text: 'Block',
    },
    {
        key: EnumDisplayMode.Grid,
        text: 'Grille',
    },
    {
        key: EnumDisplayMode.Flex,
        text: 'Flexible',
    },
    {
        key: EnumDisplayMode.Inline,
        text: 'Inline',
    },
    {
        key: EnumDisplayMode.None,
        text: 'Caché',
    },
];
export const ContainerElementPanel: FC<ContainerElementPanelProps> = (props: ContainerElementPanelProps) => {
    const { onChangeData, data } = props;
    const [, dispatch] = useContext(ExecutionDataContext);

    return (
        <>
            <Dropdown
                label='Affichage'
                options={display}
                selectedKey={data.style?.display}
                onChange={(_, option) => {
                    onChangeData({ style: { display: (option?.key as EnumDisplayMode) ?? EnumDisplayMode.Flex } });
                }}
            ></Dropdown>
            <Accordion title={'Contenu'} lightMode defaultState>
                {data.style?.display === EnumDisplayMode.Flex ? (
                    <>
                        <Dropdown
                            label='Orientation'
                            options={orientations}
                            selectedKey={data.style?.direction}
                            onChange={(_, option) => {
                                onChangeData({ style: { direction: (toVal(option?.key) as EnumBlockDirection) ?? EnumBlockDirection.Column } });
                            }}
                        ></Dropdown>
                        <Dropdown
                            label={data.style?.direction === EnumBlockDirection.Column ? 'Alignement Horizontale' : 'Alignement Verticale'}
                            title='AlignItems'
                            options={data.style?.direction === EnumBlockDirection.Column ? enumItemsAlignementHorizontal : enumItemsAlignementVerticale}
                            selectedKey={data.style?.alignItems ?? -1}
                            onChange={(_, option) => {
                                onChangeData({ style: { alignItems: toVal(option?.key) as EnumItemsAlignement } });
                            }}
                        ></Dropdown>
                        <Dropdown
                            label={data.style?.direction === EnumBlockDirection.Column ? 'Alignement Verticale' : 'Alignement Horizontale'}
                            title='justifyContent'
                            options={data.style?.direction === EnumBlockDirection.Column ? enumJustifyContentVertical : enumJustifyContentHorizontal}
                            selectedKey={data.style?.justifyContent ?? -1}
                            onChange={(_, option) => {
                                onChangeData({ style: { justifyContent: toVal(option?.key) as EnumJustifyContent } });
                            }}
                        ></Dropdown>
                    </>
                ) : (
                    <></>
                )}
                {data.style?.display === EnumDisplayMode.Grid ? (
                    <>
                        <TextField
                            label='Modele de grille'
                            value={data.style?.gridTemplates}
                            onChange={(_, val) => {
                                onChangeData({ style: { gridTemplates: val } });
                            }}
                        ></TextField>
                    </>
                ) : (
                    <></>
                )}
                <CssUnitBox
                    label='Ecart entre les éléments'
                    title='Gap'
                    vertical
                    value={data.style?.gap}
                    onChange={(val) => {
                        onChangeData({ style: { gap: val } });
                    }}
                ></CssUnitBox>
                <Toggle
                    label={'Retour à la ligne des éléments'}
                    onText='Oui'
                    offText='Non'
                    checked={data.style.flexWrap}
                    onChange={(_, flexWrap) => {
                        onChangeData({ style: { flexWrap } });
                    }}
                />
                {data.style.flexWrap ? (
                    <NumberField
                        label='Taille minimal des conteneurs enfants'
                        title='Taille minimal des conteneurs enfants'
                        inputType='Number'
                        value={data.style?.minChildrenWidth}
                        onChange={(val) => {
                            onChangeData({ style: { minChildrenWidth: val } });
                        }}
                    ></NumberField>
                ) : null}
            </Accordion>

            {/* <Dropdown
                        label='Alignement du contenu'
                        title='alignContent'
                        options={enumJustifyContent}
                        selectedKey={data.style?.alignContent ?? -1}
                        onChange={(_, option) => {
                            onChangeData({ style: { alignContent: toVal(option?.key) as EnumJustifyContent } });
                        }}
                    ></Dropdown> */}
            <Accordion title={'Conteneur'} lightMode defaultState>
                <Alignement
                    alignSelf={data.style?.alignSelf ?? -1}
                    onChangeData={(alignSelf) => {
                        onChangeData({ style: { alignSelf } });
                    }}
                />
                <NumberField
                    label='Rapport de taille dans le conteneur parent'
                    title='FlexGrow'
                    inputType='DecimalNumber'
                    value={data.style?.flexGrow}
                    onChange={(val) => {
                        onChangeData({ style: { flexGrow: val } });
                    }}
                ></NumberField>
                <Label>{"Couleur d'arriere plan"}</Label>
                <ColorPalettePicker
                    type='b'
                    idAsCssVariable
                    colorId={data.style?.background}
                    onChange={(colorId) => {
                        onChangeData({ style: { background: colorId } });
                    }}
                />

                <ResourceLookupField
                    label="Image d'arriere plan"
                    type='ResourceImageContentLookup'
                    selectedResourceId={data.style?.backgroundImageResourceId}
                    onSelectResource={(resource) => {
                        if (resource) {
                            onChangeData({ style: { backgroundImageResourceId: resource.id } });
                            dispatch({
                                type: 'addResource',
                                payload: {
                                    resourceId: resource.id,
                                    data: {
                                        id: resource.id,
                                        blobPath: resource.blobPath,
                                        data: resource.data,
                                        type: resource.resourceType,
                                    },
                                },
                            });
                        } else {
                            onChangeData({ style: { backgroundImageResourceId: undefined } });
                        }
                    }}
                />
                <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
            </Accordion>
        </>
    );
};
