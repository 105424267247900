import { Log, User, UserManager, UserManagerSettings } from 'oidc-client';
import { NavigateFunction } from 'react-router-dom';
import { notifyTokenExpired } from '../../Redux/Reducers/System/reducer';
import { store } from '../../Redux/store';

export interface ISecurityConfig {
    authority: string;
    client_id: string;
    clientRoot: string;
    scope: string;
    defaultPath?: string;
}

export type OnUserLoaded = (user: User) => void;

export class AuthenticationService {
    public userManager: UserManager;

    private defaultPath: string;

    private onLoginRequired: (() => void)[] = [];

    private mainPage: string;

    private logoutSync?: boolean;

    public currentUser: User | null = null;

    constructor(config: ISecurityConfig) {
        const settings: UserManagerSettings = {
            authority: config.authority,
            client_id: config.client_id,
            redirect_uri: `${config.clientRoot}login`,
            silent_redirect_uri: `${config.clientRoot}silentLogin`,
            post_logout_redirect_uri: `${config.clientRoot}logout`,
            //response_type: 'id_token token',
            response_type: 'code',
            revokeAccessTokenOnSignout: true,
            loadUserInfo: true,
            automaticSilentRenew: false,
            scope: config.scope,
        };
        this.logoutSync = true;
        this.defaultPath = config.defaultPath ?? '/app';
        this.mainPage = config.clientRoot;
        this.userManager = new UserManager(settings);
        this.userManager.events.addUserLoaded(async (user: User) => {
            await this.setCurrentUser(user);
        });
        this.userManager.events.addUserSignedOut(async () => {
            // eslint-disable-next-line no-console
            console.log(`receive addUserSignedOut event`);
            if (this.logoutSync) {
                store.dispatch(notifyTokenExpired());
            }
        });
        this.userManager.events.addUserUnloaded(async () => {
            // eslint-disable-next-line no-console
            console.log(`receive addUserUnloaded`);
            this.setCurrentUser(undefined);
        });
        console.log('addAccessTokenExpiring');
        this.userManager.events.addAccessTokenExpiring(() => {
            console.log('onAccessTokenExpiring');
            this.onAccessTokenExpiring();
        });
        this.setCurrentUser(); // Set the current if it exist
        Log.logger = console;
        Log.level = Log.INFO;
    }

    public onCurrentUserLoaded?: OnUserLoaded = undefined;

    public addOnloginRequired(callback: () => void) {
        this.onLoginRequired.push(callback);
    }

    public removeOnloginRequired(callback: () => void) {
        this.onLoginRequired = this.onLoginRequired.filter((ev) => ev !== callback);
    }

    public async clearStaleState(): Promise<void> {
        // prevent Error: No matching state found in storage,
        // which can happen due to duplicate state entries
        await this.userManager.clearStaleState();
        return await this.logout();
    }

    private async onAccessTokenExpiring() {
        const user = await this.getUser();
        try {
            if (user) {
                await this.renewToken();
            }
        } catch (error: /* eslint-disable @typescript-eslint/no-explicit-any */ any) {
            if (error.message === 'login_required') {
                store.dispatch(notifyTokenExpired());

                // this.onLoginRequired.forEach((ev) => ev());
                // const organization = localStorage.getItem('ORGANIZATION');
                // if (user?.expires_in) {
                //     setTimeout(() => {
                //         this.login(organization ?? '');
                //     }, user?.expires_in * 1000);
                // } else {
                //     this.login(organization ?? '');
                // }
            }
        }
    }

    // private static getUserFromStorage(): User | null {
    //     const data = localStorage.getItem('USER_DATA');
    //     if (data) {
    //         const userData = new User(JSON.parse(data));
    //         if (userData && userData.expired === false) {
    //             return userData;
    //         }
    //     }
    //     return null;
    // }

    private async setCurrentUser(user?: User): Promise<void> {
        let newUser = user;
        if (!user) {
            newUser = (await this.userManager.getUser()) as User;
        }
        if (newUser) {
            this.currentUser = newUser;
            //   localStorage.setItem('USER_DATA', JSON.stringify(newUser));
            //  if (this.onCurrentUserLoaded) {
            //      this.onCurrentUserLoaded(newUser);
            //  }
        }
    }

    public async getUser(): Promise<User | null> {
        if (this.currentUser && !this.currentUser.expired) {
            return this.currentUser;
        }
        const user = (await this.userManager.getUser()) as User;
        if (!user || user.expired) {
            this.currentUser = null;
            return null;
        }
        this.currentUser = user;
        return user;
    }

    public login(organization: string, url?: string, guestCode?: string): Promise<void> {
        let redirectUrl = url;

        if (!redirectUrl) {
            const { location } = window;
            redirectUrl = location.pathname + location.search;
            if (
                redirectUrl === '/logout' ||
                redirectUrl.startsWith('/access') ||
                redirectUrl.startsWith('/error') ||
                redirectUrl.startsWith('/web') ||
                redirectUrl.startsWith('/shared') ||
                redirectUrl.startsWith('/invalid') ||
                redirectUrl.startsWith('/login') ||
                redirectUrl.startsWith('/silentlogin')
            ) {
                redirectUrl = organization ? `/${organization}/app` : '/app';
            }
        }
        localStorage.setItem('REQUEST_URL', redirectUrl);
        localStorage.setItem('ORGANIZATION', organization);
        if (guestCode) {
            localStorage.setItem('REQUEST_URL', '');
            return this.userManager.signinRedirect({
                extraQueryParams: {
                    guestCode,
                },
            });
        }

        return this.userManager.signinRedirect({
            extraQueryParams: {
                organization,
            },
        });
    }

    public async loginCallback(url: string, history: NavigateFunction): Promise<User | null> {
        let requestUrl = localStorage.getItem('REQUEST_URL');
        if (requestUrl === '') {
            requestUrl = null;
        }
        if (this.currentUser) {
            history(requestUrl ?? this.defaultPath);
            return this.currentUser;
        }
        // remove orphan OIDC state, To prevent the Error: No matching state found in storage,
        // eslint-disable-next-line no-console

        // this.clearStaleState()
        //     .then(() => {
        //         console.log('clearStaleState success');
        //     })
        //     .catch(() => {
        //         console.log('clearStaleState failed');
        //     });
        // if (!requestUrl) {
        //     // eslint-disable-next-line no-console
        //     console.log('no REQUEST_URL, navigate to main page.');
        //     await this.login(this.mainPage);
        //     return null;
        // }
        let newUser;
        try {
            newUser = await this.userManager.signinRedirectCallback(url);
        } catch (error) {
            if (error === 'No matching state found in storage') {
                window.document.location = '/logout';
                return null;
            }
            console.error(error);
            await this.login(this.mainPage);
            return null;
        }
        try {
            if (newUser) {
                await this.setCurrentUser(newUser as User);
                // document.location.href = requestUrl ?? this.defaultPath;
                history(requestUrl ?? this.defaultPath);
                //localStorage.removeItem('REQUEST_URL');
                return newUser as User;
            }
        } catch (error) {
            console.error(error);
        }
        return null;
    }

    public async signinSilentCallback(url?: string): Promise<void> {
        try {
            await this.userManager.signinSilentCallback(url);
            await this.setCurrentUser();
        } catch (error) {
            console.error(error);
        }
    }

    public refreshToken(): Promise<User> {
        return this.renewToken();
    }

    private renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public async logout(guestCode?: string): Promise<void> {
        // eslint-disable-next-line no-console
        console.log('logout() is called');
        if (guestCode) {
            localStorage.setItem('GUEST_CODE', guestCode);
        }
        const organization = localStorage.getItem('ORGANIZATION');
        let user;
        try {
            user = (await this.userManager.getUser()) as User;

            //localStorage.removeItem('USER_DATA');
            if (user) {
                await this.userManager.removeUser().catch((reason) => {
                    console.error(reason);
                });
            }
        } catch (reason) {
            console.error(reason);
        }
        if (user) {
            this.setCurrentUser(undefined);
            await this.userManager
                .signoutRedirect({
                    id_token_hint: user && user.id_token,
                    extraQueryParams: {
                        organization,
                    },
                })
                .then(() => {
                    // eslint-disable-next-line no-console

                    console.log('signoutRedirect success');
                })
                .catch((reason) => {
                    console.error(reason);
                });
        } else {
            const guestCode = localStorage.getItem('GUEST_CODE');
            localStorage.removeItem('GUEST_CODE');
            console.log('user not found for logout.');
            // eslint-disable-next-line no-console
            await this.login('', '', guestCode ?? undefined);
        }
    }

    public async ensureLogin(organization: string, url: string, guestCode: string | undefined): Promise<boolean> {
        const currentUser = (await this.userManager.getUser()) as User;
        // if (!currentUser) {
        //     const userFromStorage = AuthenticationService.getUserFromStorage();
        //     if (userFromStorage) {
        //         this.userManager.storeUser(userFromStorage);
        //         currentUser = (await this.userManager.getUser()) as User;
        //     }
        // }

        if (!currentUser || currentUser.expired || guestCode) {
            if (this.currentUser && (guestCode || currentUser.expired)) {
                this.logout(guestCode);
                return false;
            }
            this.currentUser = null;

            await this.login(organization, url, guestCode);
            return false;
        }
        this.currentUser = currentUser;
        return true;
    }
}
