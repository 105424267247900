import { IResourceHierarchy } from '../../../../Services/SakuraApiClient';
import { DeepPartial, DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import { ObjectOperation } from '../../../../common/helpers/ObjectAndArray';

import { IMenuSetting, MenuSettingEntry } from './menuSetting';
import { getIdPart, getItemByPath, getParentPath, updateArrayAtIdPath, updateArrayAtMultipleIdPath, updateAtIdPath, updateIdPathOnChildren } from './utils';

export type MenuHierarchyAction =
    | { type: 'addItem'; payload: { idPath: string; item: DeepPartialWithArrayFunc<MenuSettingEntry> } }
    | { type: 'updateProperties'; payload: { idPath: string; propertyChanged: DeepPartial<MenuSettingEntry> } }
    | { type: 'MoveItem'; payload: { item: DeepPartialWithArrayFunc<MenuSettingEntry>; destination: DeepPartialWithArrayFunc<MenuSettingEntry>; position: 'before' | 'after' | 'inside' } }
    | { type: 'deleteItem'; payload: { idPath: string } };

export function formMenuHierarchySlicer(state: DeepPartial<IMenuSetting>, action: MenuHierarchyAction) {
    let newState: DeepPartialWithArrayFunc<IMenuSetting> = state;

    switch (action.type) {
        case 'addItem':
            newState = updateAtIdPath(action.payload.idPath, {
                children: (array: DeepPartialWithArrayFunc<MenuSettingEntry>[]) => {
                    const newArray = [...array];
                    newArray.push({ ...action.payload.item });
                    return newArray;
                },
            }) as IMenuSetting;
            break;

        case 'updateProperties':
            const [item] = getItemByPath(action.payload.idPath, state as MenuSettingEntry, undefined);
            if (item) {
                newState = updateAtIdPath(action.payload.idPath, {
                    ...action.payload.propertyChanged,
                });
            }
            break;

        case 'MoveItem':
            if (action.payload.item.id && action.payload.destination.id) {
                const srcParent = getParentPath(action.payload.item.id);
                const dstParent = getParentPath(action.payload.destination.id);

                if (srcParent === dstParent && action.payload.position !== 'inside') {
                    //la source et la destination on le même parent. on reordonne les elements
                    newState = updateArrayAtIdPath(action.payload.item.id, (array, index) => {
                        const srcIndex = array.findIndex((e) => e?.id === action.payload.item.id);
                        const src = array[srcIndex];
                        const newArray = array.filter((a) => a?.id !== action.payload.item.id);
                        const destIndex = newArray.findIndex((e) => e?.id === action.payload.destination.id);

                        const insertIndex = action.payload.position === 'before' ? destIndex : destIndex + 1;

                        if (srcIndex === insertIndex) {
                            return array; //Nothing to change
                        }
                        if (insertIndex >= newArray.length) {
                            newArray.push(src as DeepPartialWithArrayFunc<IResourceHierarchy>);
                        } else {
                            newArray.splice(insertIndex, 0, src as DeepPartialWithArrayFunc<IResourceHierarchy>);
                        }

                        return newArray;
                    });
                } else {
                    const [src] = getItemByPath(action.payload.item.id, state as MenuSettingEntry, undefined);
                    if (src) {
                        newState = updateArrayAtMultipleIdPath([
                            {
                                idPath: action.payload.item.id,
                                callback: (array, index) => {
                                    if (index !== -1) {
                                        return array.filter((a) => a.id !== src.id);
                                    }
                                    return array;
                                },
                            },
                            {
                                idPath: action.payload.destination.id,
                                callback: (array, index) => {
                                    //add the source to this place

                                    const destIndex = array.findIndex((e) => e?.id === action.payload.destination.id);
                                    const srcId = getIdPart(src.id);
                                    let parentPath = dstParent;
                                    let arrayUpdated = array;
                                    let insertIndex;
                                    if (action.payload.position === 'inside') {
                                        parentPath = array[destIndex].id;
                                        arrayUpdated = array[destIndex].children ? [...(array[destIndex].children as DeepPartialWithArrayFunc<MenuSettingEntry>[])] : [];
                                        insertIndex = arrayUpdated.length;
                                    } else {
                                        insertIndex = action.payload.position === 'before' ? destIndex : destIndex + 1;
                                    }

                                    const newSrcIdPath = `${parentPath}/${srcId}`;

                                    const indexOfPrevious = arrayUpdated.findIndex((e) => e?.id === newSrcIdPath);
                                    if (indexOfPrevious !== -1) {
                                        debugger;
                                    }
                                    //update resourcePath on children too
                                    const copySrc: MenuSettingEntry = {
                                        ...src,
                                        children: updateIdPathOnChildren(src.children, newSrcIdPath),
                                        id: newSrcIdPath,
                                    };

                                    if (insertIndex >= arrayUpdated.length) {
                                        arrayUpdated.push(copySrc);
                                    } else {
                                        arrayUpdated.splice(insertIndex, 0, copySrc);
                                    }
                                    if (indexOfPrevious !== -1) {
                                        arrayUpdated = arrayUpdated.filter((e) => !(e.id === newSrcIdPath));
                                    }

                                    if (action.payload.position === 'inside') {
                                        array = [...array];
                                        array[index] = ObjectOperation.merge(array[index], {
                                            children: arrayUpdated,
                                        });
                                        return array;
                                    } else {
                                        return arrayUpdated;
                                    }
                                },
                            },
                        ]);
                    }
                }
            }
            break;
        case 'deleteItem':
            {
                newState = updateArrayAtIdPath(action.payload.idPath, (array, index) => {
                    if (index !== -1) {
                        return array.filter((a) => a?.id !== action.payload.idPath);
                    }
                    return undefined;
                });
            }
            break;
    }
    return { newState };
}
